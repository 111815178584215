@import "src/theme/variables";

.default-toast {
  background-color: $black;
  color: $white;
}

.success-toast {
  background-color: $green;
  color: $white;
}

.error-toast {
  background-color: $red;
  color: $white;
}
