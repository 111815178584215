@import "../variables";

body {
  --warn-color: $red;
  --warn-lighter-color: $red-lighter;
  --warn-darker-color: $red-darker;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: $red,
  lighter: $red-lighter,
  darker: $red-darker,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
