/* Imports */
@import "theme/visualization/backgrounds";
@import "theme/visualization/fonts";
@import "theme/visualization/qr-styles";
@import "theme/visualization/scrolls";
@import "theme/visualization/snackbars";
@import "shared/ui/styles/labels";

/* Global configuration of texts */
* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;


  body {
    height: 100%;
    overflow: hidden;
  }
}

/* Change password modal styling*/

.change-password-modal-container .mat-dialog-container {
  border-radius: 20px;
  width: 27vw;
}

.inactive {
  visibility: hidden;

  &--spinner {
    display: none !important;
  }
}

.spinner {
  position: absolute !important;
  left: 50%;
  top: 50%;
  margin: calc((var(--loader-container-side-length, 3rem) + var(--loader-border-width, 0.3rem)) / -2) 0 0 calc((var(--loader-container-side-length, 3rem) + var(--loader-border-width, 0.3rem)) / -2);
  z-index: 10;
}

.input-file-tooltip {
  --mdc-plain-tooltip-supporting-text-size: .5rem;
}
