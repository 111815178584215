@import "material/background";
/*******************************/
// Custom Variables
/*******************************/

$primary: #006;
$primary-lighter: #3399FF;
$primary-darker: #000033;

$secondary: #006D00;
$secondary-lighter: #46D146;
$secondary-darker: #004500;

$third: #FF24C0;
$third-lighter: #ff6af3;
$third-darker: #c7008f;

$red: #f44336;
$red-lighter: #ff7961;
$red-darker: #ba000d;

$gradient-primary-warn: linear-gradient(45deg, $third, $secondary);

$white: #FFFFFF;
$disabled-grey: #dddddd;
$black: #212529;
$yellow: #ffb300;
$green: #03B472;
$cancel-red: #ff0404;
$turquoise: #36ced6;
$orange: #ff8000;
$lilac: #8081FF;

$gray: #dadadb;
$purple-lighter: #b685ff;
$purple: #85f026;
$purple-darker: #600fd9;

$mid-size-icon: 19px;
$large-size-icon: 35px;
$small-border-radius: 4px;
$mid-border-radius: 10px;
$primary-box-shadow: 0 0 14px 6px rgb(0 0 0 / 5%);
$purple-box-shadow: 0 21px 18px -20px $purple;
