.bill-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 300px;
  }
}

.blur {
  filter: blur(0.3rem);
}


.mat-tab-header{
  border-bottom: none;
}
