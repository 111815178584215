@import "../variables";

body {
  --accent-color: $secondary;
  --accent-lighter-color: $secondary-lighter;
  --accent-darker-color: $secondary-darker;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: $secondary,
  lighter: $secondary-lighter,
  darker: $secondary-darker,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
