@media screen and (min-width: 1024px) {
  * {
    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: #FFFFFF;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #DADADB;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #c2c2c2;
    }
  }
}
