@import "../variables";

.primary-color {
  color: $primary;
}

.white-color {
  color: $white;
}

.link {
  color: $secondary !important;
  text-decoration: underline;
  cursor: pointer;
}

.hash{
  color: $secondary !important;
  cursor: pointer;
}
