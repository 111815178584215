@import "../../../theme/variables";

@mixin statusStrokedLabel($color: #484848) {
  color: $color;
  padding: 1px 15px;
  border-radius: $small-border-radius;
  font-weight: bold;
  font-size: .75rem;
  border: 1px solid $color;
}

/* Label_Stroked: Start */
.label-stroked {
  &__green {
    @include statusStrokedLabel($green);
  }
  &__red {
    @include statusStrokedLabel($red);
  }
  &__primary {
    @include statusStrokedLabel($primary);
  }
  &__yellow {
    @include statusStrokedLabel($yellow);
  }
  &__lila {
    @include statusStrokedLabel($lilac);
  }
  &__orange {
    @include statusStrokedLabel($orange);
  }
  &__turquoise {
    @include statusStrokedLabel($turquoise);
  }
}
/* Label_Stroked: End */
/* Label Normal: Start */
.label {
  &__green {
    color: $green;
  }
  &__red {
    color: $red;
  }
  &__primary {
    color: $primary;
  }
  &__yellow {
    color: $yellow;
  }
  &__lila {
    color: $lilac;
  }
  &__orange {
    color: $orange;
  }
  &__turquoise {
    color: $turquoise;
  }
}
/* Label Normal: End */

.highlighted-rounded-label {
  color: $white !important;
  padding: 5px 10px;
  border-radius: $small-border-radius;
}

.label-satisfactory {
  color: $green;
}

.label-disabled {
  color: gray;
}

.label-inactive {
  color: gray;
}


.label-warning-notification {
  color: $yellow;
}

.label-priority {
  color: $red-darker;
}

.label-informative {
  color: $primary;
}

.label-no-exist {
  color: #484848;
}

.label-satisfactory-border {
  border: 1px solid $green;
}

.label-warning-notification-border {
  border: 1px solid $yellow;
}

.label-priority-border {
  border: 1px solid $red-darker;
}

.label-informative-border {
  border: 1px solid $primary;
}

.label-no-exist-border {
  border: 1px solid #484848;
}

.label-paid {
  color: $green;
  font-weight: bold;
}

.label-pending-payment {
  color: $yellow;
  font-weight: bold;
}

.label-scheduled {
  color: #2b2bee;
  font-weight: bold;
}

.label-pending-auth {
  color: $orange;
  font-weight: bold;
}

.label-not-sent {
  color: $orange;
  font-weight: bold;
}

.label-canceled {
  color: $red-darker;
  font-weight: bold;
}

.label-pending-confirmation {
  color: $yellow;
  font-weight: bold;
}

.label-confirmed {
  color: $green !important;
  font-weight: bold;
}

.label-in_process {
  color: $yellow !important;
  font-weight: bold;
}

.label-unconfirmed {
  color: $red !important;
}

.label-confirmed_detail {
  color: $green !important;
  font-size: 12px;
}

.label-in_process_detail {
  color: $yellow !important;
  font-size: 12px;
}

.label-unconfirmed_detail {
  color: $red !important;
  font-size: 12px;
}

.label-pending-payment_detail {
  color: $yellow !important;
  font-size: 12px;
}

.label-partial-payment-bills {
  color: $primary;
  font-weight: bold;
}

.label-canceled {
  color: $red;
  font-weight: bold;
}

.label-unpaid {
  color: $red-lighter;
  font-weight: bold;
}

.label-error {
  @extend .highlighted-rounded-label;
  background-color: $red;
}

.label-warning {
  @extend .highlighted-rounded-label;
  background-color: $yellow;
}


.label-success {
  @extend .highlighted-rounded-label;
  background-color: $green;
}

.label-authorized {
  color: $third;
  font-weight: bold;
}

.label-earn-pending {
  color: $orange;
}

.label-earn-confirmed {
  color: #8081FF;
}

.label-earn-active {
  color: $green;
}

.label-earn-finished {
  color: #5BB198;
}

.label-earn-pending-refund {
  color: $orange;
}

.label-earn-refund {
  color: #5BB198;
}

.label-swap-sending{
  color: #E38130;
}
.label-swap-sent{
  color: #49A442;
}
.label-swap-approved{
  color: #8081FF;
}
.label-swap-in_process{
  color: #F37A01 ;
}
.label-swap-canceled{
  color: #FF0404 ;
}

.label-swap-pending-cancellation{
  color: #cb552e;
}

@mixin colored-label($background-color, $font-color) {
  -webkit-border-radius: $small-border-radius;
  -moz-border-radius: $small-border-radius;
  border-radius: $small-border-radius;

  border: none;
  outline: none;

  padding: 6px 14px;

  background-color: $background-color;
  color: $font-color;
  font-size: 14px;
}
