@import "../variables";

body {
  --primary-color: $primary;
  --primary-lighter-color: $primary-lighter;
  --primary-darker-color: $primary-darker;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: $primary,
  lighter: $primary-lighter,
  darker: $primary-darker,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
